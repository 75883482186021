import React, { useContext, useEffect, useState } from 'react';
import Panel from '../Panel';
// import { searchOrders } from '../libs/requests/orderSearch';
import { authContext } from '../../containers/AuthProvider';
import { Link, useParams } from 'react-router-dom';
import { Accordion, AccordionContent, AccordionTitle, Button, Comment, CommentAuthor, CommentContent, CommentGroup, CommentMetadata, CommentText, Dimmer, Divider, Form, FormTextArea, Grid, GridColumn, Icon, Label, Loader, Message, Segment } from 'semantic-ui-react';
import { searchServices } from '../../libs/requests/serviceSearch';
import { GridItem } from '../ViewOrder';
import { Header } from 'react-bootstrap/lib/Modal';

const fields = [
  { key: "reference", label: "Reference" },
  { key: "appointmentDate", label: "Appointment Date" },
  { key: "siteContactName", label: "Site Contact" },
  { key: "siteContactPhoneNumber", label: "Site Contact Number" },
  { key: "raisedDate", label: "Raised" },
  { key: "requestedBy", label: "Requested By" },
  { divider: true },
];

const lipsum = `We are writing to inform you of an open fault affecting your broadband service. Below are the details of the fault. Our engineers are currently investigating the issue and are working diligently to resolve it as quickly as possible. We understand the importance of your broadband connection, and we sincerely apologize for any inconvenience this may have caused. If further actions are required or if we receive any updates from our technical team, we will notify you promptly. In the meantime, please feel free to reach out with any questions or additional information that may assist us in resolving the fault more efficiently. We appreciate your patience and cooperation during this time, and we are committed to restoring your service as soon as possible. Your satisfaction is our priority, and we will continue to keep you informed every step of the way.`



const dummyFault = {
  reference: 'ZW00043910SK-945',
  state: 'Open',
  requestedBy: 'David Stewart',
  appointmentDate: '12/02/24',
  siteContactName: 'Tech Support',
  siteContactPhoneNumber: '01706 902000',
  description: lipsum,
  raisedDate: '12/02/24',
  comments: [
    {
      author: 'Zen',
      date: '12/02/24',
      text: 'Comment from Zen'
    },
    {
      author: 'Customer',
      date: '13/02/24',
      text: 'Comment from Customer'
    }
  ]
}

const Fault = ({ fault, isOpen }) => {
  return (
    <>
      <Grid columns={2}>
        {fields.map((field) => (
          <GridItem
            field={field}
            value={fault[field.key]}
            key={field.key}
          />
        ))}
      </Grid>
      <Grid width={1}>
        <GridColumn>
          <strong>Description</strong><br /> <br />
          {fault.description}
        </GridColumn>
      </Grid>
      <Grid>
        <GridColumn>
          <CommentGroup>
            <Header as='h3' dividing>
              Comments
            </Header>
            {fault.comments.map(comment => (
              <Comment>
                <CommentContent>
                  <CommentAuthor as='strong'>{comment.author}</CommentAuthor>
                  <CommentMetadata>
                    <span>{comment.date}</span>
                  </CommentMetadata>
                  <CommentText>{comment.text}</CommentText>
                </CommentContent>
              </Comment>
            ))}
          </CommentGroup>
        </GridColumn>
      </Grid>
      {isOpen && (
        <Grid>
          <GridColumn>
            <Form reply>
              <FormTextArea />
              <Button className="button-primary" content='Reply' labelPosition='left' icon='edit' primary />
            </Form>
          </GridColumn>
        </Grid>
      )}

    </>
  )
}

export default function Faults() {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [order, setOrder] = useState({});
  const [service, setService] = useState({});
  const [openFault, setOpenFault] = useState(dummyFault);
  const [closedFaults, setClosedFaults] = useState([dummyFault, dummyFault, dummyFault]);
  const [activeIndex, setActiveIndex] = useState(-1);

  const { reference } = useParams();
  const { permissions } = useContext(authContext);

  useEffect(() => {
    (async () => {
      try {
        if (permissions.servicesAccess) {
          // setIsLoading(true);
          // setService((await searchServices(reference))[0]);
          // setIsLoading(false);
        }
      }
      catch (err) {
        console.error(err);
        setIsError(true);
        setIsLoading(false);
      }
    })();
  }, []);


  function handleClick(e, titleProps) {
    const { index } = titleProps
    // const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    // this.setState({ activeIndex: newIndex })
    setActiveIndex(newIndex);
  }

  if (!isLoading && !permissions.servicesAccess) {
    return (
      <Panel>
        <Message error header="You don't have permission to view this page" />
      </Panel>
    )
  }

  // const header = <>
  //   <span>{`Reference ${reference}`}</span>
  //   <strong style={{ float: 'right' }}>{order ? order.status : ''}</strong>
  // </>

  return (
    <>
      {/* <Segment> */}
      <Dimmer active={isLoading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      {permissions.demoUser && (
        <>
          {openFault && <Panel header={'Open Fault'}>
            <Fault fault={openFault} isOpen={true} />
          </Panel>}

          {closedFaults.length && <Panel header={'Recently Closed Faults'}>
            <Accordion fluid styled>

              {closedFaults.map((fault, idx) => {
                // return <Fault fault={fault} isOpen={false} />
                return (
                  <>
                    <AccordionTitle
                      active={activeIndex === idx}
                      index={idx}
                      onClick={handleClick}
                      key={fault.reference}
                    >
                      <Label ribbon color='red'>Closed</Label>
                      <Icon name='dropdown' />
                      {fault.reference} ({fault.raisedDate})
                    </AccordionTitle>
                    <AccordionContent active={activeIndex === idx}>
                      <Fault fault={fault} isOpen={false} />
                    </AccordionContent>
                  </>
                )
              })}
            </Accordion>

          </Panel>}
        </>
      )
      }
      {/* </Segment> */}
    </>
  )
}