import React, { useEffect, useState, Component } from 'react';
import Panel from '../Panel';
import { Form, Radio, Segment, FormInput, Grid, GridColumn, GridRow, FormGroup, Divider, Dropdown, Button, Message } from 'semantic-ui-react';
import { useHistory } from "react-router-dom";
import { useFormik, withFormik, useField } from 'formik';
import '../OrderComponents/ZenMigration.css'
import addressSearchRequest from '../../libs/requests/addressSearch';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import { format, compareAsc } from 'date-fns'
import '../OrderComponents/RadioStyles.css'
import postTalkTalkOrder from '../../libs/requests/postTalkTalkOrder';
import { parse } from 'query-string';
import { ActivationDate } from './ActivationDate';
import {getSupplier} from '../../libs/sessionVariable';

export default function TTBStandardFTTCOverWLROrder() {

  const selectedProduct = sessionStorage.getItem('selectedProduct');
  const product = JSON.parse(selectedProduct);
  const [radioButtons, setRadioButtons] = useState('0');
  const [isLoading, setIsLoading] = useState(false);
  const todaysDate = new Date();
  const [level, setLevel] = useState({careLevelField: "0"});
  const [commandID, setCommandID] =  useState(0);
  const postCode = { 'postCode': !product?.orderDetails?.zenLineChars?.btOpenreachPostCode ? product?.orderDetails?.zenLineChars?.btWholesalePostCode : product?.orderDetails?.zenLineChars?.btOpenreachPostCode }
  const [addressResults, setAddressResults] = useState([]);
  const [addressValue, setAddressValue] = useState({addressField: {addressReferenceNumber: '', districtCode: '', qualifier: ''}});
  const [errorMessage, setErrorMessage] =  useState([]);
  const INTERNAL_SERVER_ERROR_MESSAGE = "Internal server error - an error occured on the server, please try again"
  const [supplier, setSupplier] = useState('');

  useEffect(() => {
    async function fetchSuppllier(){
      const defaultSupplier = await getSupplier('TalkTalk Business');
      setSupplier(defaultSupplier);
    }
    fetchSuppllier();
  }, 
  )

  const addressSearch = async (postCode) => {
    try {
      return await addressSearchRequest(postCode);
    } catch (err) {
      console.log(err);
    }
  }
  
  const getAddresses = async (postCode) => {
    const result = await addressSearch(postCode)
    console.log(result);
  
    const optionsList = [];
  
    result.forEach(function (element) {
      optionsList.push({ key: element.addressReference, value: element.addressReference, text: element.address, flag: element.addressReference })
    })
  
    console.log(optionsList);
  
    return optionsList;
  }
  
  const careLevels= [
    { key: 0, value: 0, text: 'Standard', value: '0', flag: 0 },
    { key: 1, value: 1, text: 'Enhanced', value: '1', flag: 1 },
    { key: 2, value: 2, text: 'Critical', value: '2', flag: 2 },
  ];
  
  const ips = [
    { key: 1, value: 1, text: '1', flag: 1 },
    { key: 4, value: 4, text: '4', flag: 4 }
  ]

  const handleAddressChange = (event, result) => {
    const { name, value } = result || event.target;
    setAddressValue({ ...addressValue, [name]: value });
    setFieldValue('addressField', value);
  };

  const handleCareLevelChange = (event, result) => {
    const { name, value } = result || event.target;
    setLevel({ ...level, [name]: value });
  };

  const handleRadioChange = (e) => {
    setRadioButtons(e.currentTarget.value);
    setFieldValue('noOfIps', e.currentTarget.value);
  };

  useEffect(() => {
    async function getAddressResults() {
      const response = await getAddresses(postCode.postCode).then(data => setAddressResults(data));
    }
    getAddressResults();
  }, []);

  useEffect(() => {
    function getDatePicker() {
      const response = <DatePicker selected={format(startDate, 'dd.MM.yyyy')} onChange={(date) => setStartDate(format(date, 'dd.MM.yyyy'))} />
      setFieldValue('activationDateField', startDate);
    }
    getDatePicker();
  }, []);

  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  const [startDate, setStartDate] = useState(new Date(tomorrow));

  const handleStartDateChange = (e, date) => {
    setStartDate(e);
    setFieldValue('activationDateField', e);
  };

  const { values, handleChange, handleSubmit, setFieldValue, isValid, errors, ...formik } = useFormik({
    onSubmit,
    validate,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: {
      siteInformation: '',
      addressField: {addressReferenceNumber: '', districtCode: '', qualifier: ''},
      noOfIps: '1',
      activationDateField: '',
      orderRef: '',
      contactTel: '',
      contactName: ''
    }
  });

  function validate({ siteInformation, addressField, noOfIps, activationDateField, orderRef, contactTel, contactName}) {
    const errors = {};

    if (!siteInformation.length) {
      errors.siteInformation = true;
    }

    if (!addressField.addressReferenceNumber.length) {
      errors.addressField = true;
    }
    
    if (!noOfIps.length) {
      errors.noOfIps = true;
    }

    if (!orderRef.length) {
      errors.orderRef = true;
    }

    if (!contactTel.length) {
      errors.contactTel = true;
    }

    if (!contactName.length) {
      errors.contactName = true;
    }
    
    return errors;
  }

  async function onSubmit({siteInformation, addressField, noOfIps, activationDateField, orderRef, contactTel, contactName}) {
    setIsLoading(true);

    const selectedAddress = addressResults.find(element => element.key.addressReferenceNumber === addressField.addressReferenceNumber);

    const premiseIdentifier = selectedAddress.text;
    const premise = premiseIdentifier.split(',');
    const isPremiseNameOrThoroughfare = parseInt(premise[0]);
    const usePremiseName = isNaN(isPremiseNameOrThoroughfare);
    const premiseName =  usePremiseName ? premise[0] : null;
    const thoroughFareNumber = !usePremiseName ? premise[0] : null;
    let activationDate = !document.getElementById("activationDateField") ? "" : document.getElementById("activationDateField").defaultValue;
    let year = activationDate.substring(6, 10);
    let month = activationDate.substring(3, 5);
    month = parseInt(month) - 1;
    let day = activationDate.substring(0, 2);
    activationDate = new Date(year, month, day).toISOString();

    const thoroughfarNumberIdentifier = {"$type":"NGAPublicProvisioning.ThoroughfareNumberIdentifier, Client","ThoroughfareNumber":thoroughFareNumber};
    const premiseNameIdentifier = {"$type":"NGAPublicProvisioning.PremisesNameIdentifier, Client","PremisesName":premiseName};

    try {
      const javaObject = {Command:{$type:"NGAPublicProvisioning.NGAProvideCommand, Client", CareLevel:{CareLevelValue: parseInt(level.careLevelField)}, AccessType:{$type:"NGAPublicProvisioning.ManagedWLRAccessType, Client",Authentication:{$type:"NGAPublicProvisioning.GenerateUsernameIdentifierType, Client",DomainID:1363},"StaticIPAddresses":{"OtherUses":{"OtherUsesDescription": noOfIps === "2" ? "4 static ips required" : "1 static ip required","OtherUsesQuantity": noOfIps === "2" ? 4 : 1 },"StaticIPAddresses":noOfIps === "2" ? 2 : 1 ,"MailServers":0,"WebServers":0,"FirewallServers":0,"VPNDevices":0,"RemoteAccessServers":0,"DatabaseServers":0,"Expansion":0}, IPTVService:{IPTVServiceState:0}, DSLProductCode:product.productCode}, SiteInformation:{NameHeader:siteInformation,BusinessSuffix:null,PremisesIdentifier:(usePremiseName ? premiseNameIdentifier :  thoroughfarNumberIdentifier),ThoroughfareName:premiseName,Locality:null,PostCode:postCode.postCode},CustomerRequiredByDate:activationDate,ProvisioningIdentifier:{$type:"NGAPublicProvisioning.ExistingCLIProvisioningIdentifier, Client",CLI:product?.orderDetails?.request?.phoneNumber},ContactName:contactName,ContactTelephoneNumber:contactTel,InstallationType:{$type:"NGAPublicProvisioning.PCPOnlyInstallType, Client", ResellerReference:orderRef}}};
      const body = JSON.stringify(javaObject);
      setErrorMessage([]);
      setCommandID(await postTalkTalkOrder(body));
      setErrorMessage([]);
      setIsLoading(false);
      
    } catch (ex) {
      selectErrorBannerMessage(ex)
      setIsLoading(false);
    }
  }

  function selectErrorBannerMessage(error) {

    const message = error.message;

      if(typeof message !== 'undefined' && message.includes('Internal Server Error')) {
        setErrorMessage(existingArray => [existingArray, INTERNAL_SERVER_ERROR_MESSAGE]);
      }

      else if (Array.isArray(error) && error.length > -1){
          setErrorMessage(error.map(e => e.ErrorMessage));
      }
    }

    return (
      <>
        <Panel header={supplier + ' ' + product.productName}>
        {errorMessage.length !== 0 && <Message error header='There was some errors with your submission' list={
         errorMessage
         }
        />}
  
        {commandID !== 0 && <Message success header='Your submission was successful'  content={'Order reference: ' + commandID}
        />}
       
          <Form onSubmit={useFormik.handleSubmit} className='disabled'>
          <Grid columns={1}>
            <GridRow>
              <GridColumn>
                <FormGroup>
                  <FormInput
                    style={{ width:"250px" }} 
                    inline
                    label='Line Cli'
                    name='phoneNumberLabel'
                    data-testid='phoneNumber'
                    disabled={true}
                    value={product?.orderDetails?.request?.phoneNumber}
                    required
                  />
                </FormGroup>
                <Divider></Divider>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn>
                <FormGroup>
                  <FormInput
                    style={{ width:"250px" }} 
                    inline
                    label='Product'
                    name='productLabel'
                    data-testid='productCode'
                    disabled={true}
                    value={product.productCode}
                    required
                  />
                </FormGroup>
                <Divider></Divider>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn>
                <FormGroup>
                  <FormInput
                    style={{ width:"250px" }} 
                    inline
                    label='Provision Type'
                    name='provisionLabel'
                    data-testid='provisioningType'
                    disabled={true}
                    value={product?.orderDetails?.provisionType}
                    required
                  />
                </FormGroup>
                <Divider></Divider>
                <FormGroup>
                  <Form.Field
                    label='Site Information'
                    required
                  />
                </FormGroup>
                <Grid>
                  <Grid.Row centered>
                    <Grid.Column width={12}>
                      <Form>
                        <Form.Field>
                          <Form.Input 
                          placeholder="Enter BusinessName or Surname" 
                          id="siteInformation"
                          name="siteInformation"
                          value={values.siteInformation}
                          onChange={handleChange}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Form.Input 
                          placeholder="Enter Contact Name" 
                          id="contactName"
                          name="contactName"
                          value={values.contactName}
                          onChange={handleChange}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Form.Input 
                          placeholder="Enter Contact Telephone Number" 
                          id="contactTel"
                          name="contactTel"
                          value={values.contactTel}
                          onChange={handleChange}
                          />
                        </Form.Field>
                        <FormGroup>
                          <Form.Field
                            inline
                            name='addressLabel'
                            data-testid='addressDropDown'>
                          </Form.Field>
                        </FormGroup>
                        <FormGroup>
                          <Dropdown
                            placeholder='Select Address'
                            fluid
                            search
                            selection
                            options={addressResults || []}
                            label='addressField'
                            id="addressField"
                            name="addressField"
                            onChange={(e, val) => handleAddressChange(e, val, setFieldValue)}
                            value={addressValue.addressField}
                          />
                        </FormGroup>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Divider></Divider>
                <FormGroup>
                  <Form.Field
                    label="Care Level"
                    required
                  />
                </FormGroup>
                <Grid>
                  <Grid.Row centered>
                    <Grid.Column width={12}>
                      <Form>
                        <FormGroup>
                          <Dropdown
                            placeholder='Select Care Level'
                            fluid
                            search
                            selection
                            options={careLevels}
                            label='careLevelField'
                            id='careLevelField'
                            name='careLevelField'
                            defaultValue={careLevels[0].value}
                            onChange={handleCareLevelChange}
                            value={level.careLevelField}
                          />
                        </FormGroup>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Divider></Divider>
                <FormGroup>
                  <Form.Field
                    inline
                    label='No of IP Addresses'
                    name='ipsLabel'
                    data-testid='ipsLabel'
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Form.Field>
                    <input type="radio"
                      name="noOfIps"
                      value={ips[0].value}
                      defaultChecked
                      type="radio"
                      className='ui.radio'
                      onChange={(e) => handleRadioChange(e, setFieldValue)}
                    />
                  </Form.Field>
                  <label>1</label>
                  <Form.Field>
                    <input type="radio"
                      name="noOfIps"
                      value={ips[1].value}
                      type="radio"
                      className='ui.radio'
                      onChange={(e, val) => handleRadioChange(e, val, setFieldValue)}
                    />
                  </Form.Field>
                  <label>4</label>
                </FormGroup>
                <Divider></Divider>
                {<ActivationDate show={true} today={new Date()} leadTimeDays={product?.orderDetails?.provisionType === 'Migration' ? 9 : 5} />}
                <FormGroup>
                  <Form.Field
                    inline
                    label='References'
                    name='references'
                    data-testid='referencesLabel'
                    disabled={true}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    inline
                    label='Order Reference'
                    name='orderRef'
                    data-testid='orderRefLabel'
                    disabled={false}
                    value={values.orderRef}
                    onChange={handleChange}
                  />
                </FormGroup>
                <Button
                  disabled={!isValid || commandID != 0}
                  className='button-primary'
                  floated='right'
                  content='Place Order'
                  onClick={handleSubmit}
                  loading={isLoading}
                />
              </GridColumn>
            </GridRow>
          </Grid>
        </Form>
      </Panel>
    </>
  )
}