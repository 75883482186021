import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'semantic-ui-react';
import Panel from '../Panel';
import ZenBroadbandGroup from './ZenBroadbandGroup';
import './ZenAvailabilityResults.css';
import { Message } from 'semantic-ui-react';
import {showCustomMessage} from '../../libs/sessionVariable';

export default function ZenAvailabilityResults({ results }) {
  const [selectedProduct, selectProduct] = useState(null);
  const [customMessage, setCustomMessage] = useState(false);
  const history = useHistory();
  const {broadbandGroups, lineDetails} = results;

  useEffect(() => {
    async function customMesssageHook() {
      setCustomMessage(await showCustomMessage());
    }
    customMesssageHook();
}, [customMessage]);

  function PlaceOrder() {

    // 1 - New Provide 
    // 2 - Migration
    // 3 - Not allowed to order
    // 4 - Sim Provide
    // 5 - Succession Provide
    // 6 - Succession Provide or a Migration 
    // 7 - Migration or new provide
    // TODO - Remove this

    sessionStorage.setItem('selectedProduct', JSON.stringify(selectedProduct));

    if (selectedProduct?.broadbandType === "GFast" && selectedProduct?.orderDetails?.provision?.provisionType === 2) {
      history.push('/PlaceGFastMigrationOrder'); 
    }

    else if (selectedProduct?.broadbandType === "GFast" && selectedProduct?.orderDetails?.provision?.provisionType === 1) {
      history.push('/PlaceGFastNewProvideOrder'); 
    }

    else if (selectedProduct?.broadbandType === "GFast" && selectedProduct?.orderDetails?.provision?.provisionType === 4) {
      history.push('/PlaceGFastSIMProvideOrder'); 
    }

    else if (selectedProduct?.broadbandType === "FTTP" && selectedProduct?.orderDetails?.provision?.provisionType === 1) {
      history.push('/PlaceFTTPNewProvideOrder'); 
    }

    else if (selectedProduct?.broadbandType === "FTTP" && selectedProduct?.orderDetails?.provision?.provisionType === 2) {
      history.push('/PlaceFTTPMigrationOrder');
    }

    else if (selectedProduct?.broadbandType === "FTTP" && selectedProduct?.orderDetails?.provision?.provisionType === 5) {
      history.push('/PlaceFTTPSuccessionProvideOrder');
    }

    else if (selectedProduct?.broadbandType === "FTTP" && selectedProduct?.orderDetails?.provision?.provisionType === 6) {
      history.push('/PlaceFTTPSuccessionOrMigrationOrder');
    }

    else if (selectedProduct?.broadbandType === "FTTP" && selectedProduct?.orderDetails?.provision?.provisionType === 7) {
      history.push('/PlaceFTTPMigrationOrNewOrder');
    }

    else if (selectedProduct?.orderDetails?.provision?.provisionType === 1 && selectedProduct?.broadbandType === "Copper"
      || selectedProduct?.orderDetails?.provision?.provisionType === 2 && selectedProduct?.broadbandType === "Copper") {
      history.push('/PlaceZenCopperOrder');
    }

    else if (selectedProduct?.orderDetails?.provision?.provisionType === 4 && selectedProduct?.broadbandType === "Copper")
      {
      history.push('/PlaceZenSimProvideCopperOrder');
    }

    else if (selectedProduct?.orderDetails?.provision?.provisionType === 1 && selectedProduct?.broadbandType === "FTTC"
      || selectedProduct?.orderDetails?.provision?.provisionType === 2 && selectedProduct?.broadbandType === "FTTC") {
      history.push('/PlaceZenFTTCOrder');
    }

    else if (selectedProduct?.orderDetails?.provision?.provisionType === 4 && selectedProduct?.broadbandType === "FTTC") {
      history.push('/PlaceZenSimProvideFTTCOrder');
    }

    else if(selectedProduct?.orderDetails?.accessLines && selectedProduct?.orderDetails?.accessLines[0]?.lineType === "StoppedLine" && selectedProduct?.broadbandType === "SOGEA"
      
    )
    {
      history.push('/PlaceSOGEARestartOrder');
    }

    else if (selectedProduct?.broadbandType === "SOGEA" && selectedProduct?.orderDetails?.provision?.provisionType === 7 ||
    selectedProduct?.broadbandType === "SOGEA" && selectedProduct?.orderDetails?.provision?.provisionType === 1 ||
    selectedProduct?.broadbandType === "SOGEA" && selectedProduct?.orderDetails?.provision?.provisionType === 2) {
      history.push('/PlaceSOGEAOrder');
    }

    else if (selectedProduct?.orderDetails?.provisionType === 'Migration' && selectedProduct?.broadbandType === 'FTTC' || selectedProduct?.orderDetails?.provisionType === 'NewProvide' && selectedProduct?.products?.provider === 'Fibre1' && selectedProduct?.broadbandType === 'FTTC') {
      history.push('/PlaceTtbFttcOverWlr');
    }

    else if (selectedProduct?.orderDetails?.provisionType === 'SimProvide' && selectedProduct?.broadbandType === 'FTTC' && selectedProduct?.orderDetails?.request.postCode) {
      history.push('/PlaceTtbFttcOverWlrSim');
    }
    else if (selectedProduct?.broadbandType === "FTTP SYMMETRICAL") {
      history.push('/placefttpsymmetrical', { storage: selectedProduct });
    }
  }

  return (
    <>
    <div className='zen-availability-results'>
      {broadbandGroups.map(broadbandGroup => (
        <ZenBroadbandGroup
          key={broadbandGroup.broadbandType}
          broadbandGroup={broadbandGroup}
          selectProduct={selectProduct}
          selectedProduct={selectedProduct}
          lineDetails={lineDetails}
        />
      ))}
      <Button
        disabled={selectedProduct === null}
        onClick={PlaceOrder}
        className='button-primary'
        floated='right'
        content='Place Order'
      />
      
    </div>
          {!lineDetails?.FttpSymmetrical && customMessage && <Message success header='Unfortunately there are no FTTP Symmetrical products available at this location, please contact your account manager ' />}
    </>
  );
}