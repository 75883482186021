import React, { useContext, useEffect, useState } from 'react';
import Panel from '../Panel';
// import { searchOrders } from '../libs/requests/orderSearch';
import { authContext } from '../../containers/AuthProvider';
import { useParams } from 'react-router-dom';
import {  Button, ButtonGroup, Checkbox, Form, Grid, GridColumn, GridRow, Input, Message } from 'semantic-ui-react';

const diagnosticChecks = [
  'Test socket',
  'Alternative filler',
  'Tested with only router connected',
  'Line has correct DN',
  'Alternative router',
  'Alternative RJ11 cable',
  'Quiet line test'
]

const ControlledButtonGroup = ({options, defaultValue, onChange}) => {
  const [selectedVal, setSelectedVal] = useState(defaultValue);

  const handleClick = (val) => {
    setSelectedVal(val);
    if (!!onChange) onChange(val);
  }

  return (
    <ButtonGroup>
      {options.map(option => <Button
        basic 
        onClick={() => handleClick(option)}
        color={selectedVal === option ? 'teal' : 'black'}
      >
        {option}
      </Button>)}
    </ButtonGroup>
  );
}

export default function RaiseFault({onCancel}) {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { reference } = useParams();
  const { permissions } = useContext(authContext);
  const [faultType, setFaultType] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        if (permissions.servicesAccess) {
          // setIsLoading(true);
          // setService((await searchServices(reference))[0]);
          // setIsLoading(false);
          setTimeout(() => {
            setIsLoading(false);
          }, 200)
        }
      }
      catch (err) {
        console.error(err);
        setIsError(true);
        setIsLoading(false);
      }
    })();
  }, []);

  const faultTypeSelected = (faultType) => {
    console.log('fault type selected', faultType);
    setFaultType(faultType);
  }

  if (!isLoading && !permissions.servicesAccess) {
    return (
      <Panel>
        <Message error header="You don't have permission to view faults" />
      </Panel>
    )
  }

  return (
    <>
      <Panel header='Raise Fault' style={{minWidth: '60em'}}>
        <Form>
        <Grid className="raise-fault">
          <GridRow>
            <GridColumn width={4}>
              <strong>Fault condition</strong>
            </GridColumn>
            <GridColumn width={12}>
              <ControlledButtonGroup
                options={['Sync', 'Auth', 'Performance']}
                onChange={(val) => faultTypeSelected(val)}
              />
            </GridColumn>
          </GridRow>
          {!!faultType && (
            <>
              <GridRow>
                <GridColumn width={4}>
                  <strong>Description</strong>
                </GridColumn>
                <GridColumn width={12}>
                  <Input size="massive" />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={4}>
                  <strong>Permanent or Intermittent?</strong>
                </GridColumn>
                <GridColumn width={12}>
                  <ControlledButtonGroup
                    options={['Permanent', 'Intermittent']}
                  />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={4}>
                  <strong>Which diagnostic checks have been performed?</strong>
                </GridColumn>
                <GridColumn width={12}>
                  {diagnosticChecks.map(check => <Checkbox label={check} key={check} />)}
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={16}>
                  Please provide on-site contact details in the eventuality an engineer appointment is required
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={4}>
                  <strong>On-site contact name</strong>
                </GridColumn>
                <GridColumn width={12}>
                  <Input />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={4}>
                  <strong>On-site contact phone number</strong>
                </GridColumn>
                <GridColumn width={12}>
                  <Input />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={4}>
                  <strong>Preferred appointment</strong>
                </GridColumn>
                <GridColumn width={12}>
                  <ControlledButtonGroup
                    options={['First available', 'AM', 'PM']}
                  />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={4}>
                  <strong>Additional appointment details</strong>
                </GridColumn>
                <GridColumn width={12}>
                  <Input size="massive" />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={4}>
                  <strong>Accept charges?</strong><br />
                  Charges will be raised in the event that no fault was identified with the supplier network, or the engineer is unable to access the site
                </GridColumn>
                <GridColumn width={12}>
                  <Checkbox></Checkbox>
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn width={16}>
                  <Button primary className="button-primary">Submit fault</Button>
                  <Button secondary className="button-secondary" floated="right" onClick={onCancel}>Cancel</Button>
                </GridColumn>
              </GridRow>
            </>
          )}
        </Grid>
        </Form>
      </Panel>
    </>
  )
}