import React from "react";
import { Form, Button, Message } from "semantic-ui-react";
import "./AvailabilityForm.css";
import { useFormik } from "formik";
import Panel from "./Panel";

const POST_CODE_REGEX = /^([a-zA-Z]{1,2}\d[a-zA-Z\d]?) ?(\d[a-zA-Z]{2})$/;
const PHONE_NUMBER_REGEX = /^0([1-6]\d{8,10})$/;

export const PHONE_NUMBER_ERROR_MESSAGE =
  "Please enter a valid UK landline number";
export const POST_CODE_ERROR_MESSAGE = "Please enter a valid UK Post Code";
export const ADDRESS_SEARCH_NO_RESULTS =
  "We were unable to find any addresses for this post code please check it and try again.";
export const AVAILABLITY_CHECK_NO_RESULTS =
  "No available products for the selected address";
export const SERVER_ERROR_MESSAGE = "A server error occured, please try again.";

const validate = ({ phoneNumber, postCode, address }) => {
  const errors = {};

  if (!phoneNumber && !address) {
    errors.address = true;
  }

  if (phoneNumber && !phoneNumber.match(PHONE_NUMBER_REGEX)) {
    errors.phoneNumber = true;
  }

  if (postCode && !postCode.match(POST_CODE_REGEX)) {
    errors.postCode = true;
  }

  return errors;
};

function selectErrorBannerMessage(availablityCheck, addressSearch) {
  const availablityError = availablityCheck.error?.message;
  const addressError = addressSearch.error?.message;
  const noResults = availablityCheck?.data?.broadbandGroups?.length === 0;


  if (addressError === "No Results") {
    return {
      header: "No Results",
      content: ADDRESS_SEARCH_NO_RESULTS,
    };
  }

  if (availablityError === "No Results") {
    return {
      header: "No Results",
      content: AVAILABLITY_CHECK_NO_RESULTS,
    };
  }

  if (availablityError === "Server Error" || addressError === "Server Error") {
    return {
      header: "Server Error",
      content: SERVER_ERROR_MESSAGE,
    };
  }

  if (noResults) {
    return {
      header: "No Results",
      content: AVAILABLITY_CHECK_NO_RESULTS,
    };
  }

  return null;
}

export default function AvailabilityForm({ availablityCheck, addressSearch }) {
  const errorBannerMessage = selectErrorBannerMessage(
    availablityCheck,
    addressSearch
  );
  const { values, errors, touched, ...formik } = useFormik({
    validate,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: {
      phoneNumber: "",
      postCode: "",
      address: "",
    },
  });

  function onAvailablityFormSubmit() {
    if (!errors.address && !errors.phoneNumber) {
      const selectedAddress = addressSearch.data?.find(
        (x) => x.id === values.address
      );
      const silverAddress = addressSearch.data?.find(
        (x) => x.address === values.address
      );

      const address =
        typeof selectedAddress === "undefined"
          ? silverAddress
          : selectedAddress;
      const phoneNumber = values.phoneNumber;
      sessionStorage.setItem(
        "customerDetails",
        JSON.stringify({ phoneNumber, selectedAddress })
      );
      availablityCheck.makeRequest(phoneNumber, address);
    }
  }

  function onPostCodeFormSubmit() {
    if (values.postCode && !errors.postCode) {
      const [_, area, street] = values.postCode.match(POST_CODE_REGEX);
      formik.setFieldTouched("postCode", true);
      formik.setFieldValue("address", "");
      availablityCheck.reset();
      addressSearch.makeRequest(`${area} ${street}`);
    }
  }

  function showOrHideError(e) {
    const inputName = e.nativeEvent.target.name;

    if (touched[inputName]) {
      formik.setFieldTouched(inputName, false);
    }

    if (e.keyCode === 13) {
      formik.setFieldTouched(inputName, true);
    }
  }

  return (
    <>
      <Panel header="Enter details to check availability">
        <p className="availability__help-text">
          Enter a UK landline number or search for an address below to perform
          an availability check. Phone numbers will provide the most accurate
          results.
        </p>

        <Form onSubmit={onAvailablityFormSubmit} autoComplete="off">
          <Form.Group>
            <Form.Input
              inline
              className={
                touched.phoneNumber && errors.phoneNumber ? "error" : ""
              }
              label="Phone Number"
              name="phoneNumber"
              data-testid="phoneNumber"
              disabled={availablityCheck.loading}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onKeyDown={showOrHideError}
            />
          </Form.Group>
          {touched.phoneNumber && errors.phoneNumber && (
            <span className="availability__error">
              {PHONE_NUMBER_ERROR_MESSAGE}
            </span>
          )}
        </Form>

        <Form onSubmit={onPostCodeFormSubmit} autoComplete="off">
          <Form.Group>
            <Form.Input
              inline
              className={touched.postCode && errors.postCode ? "error" : ""}
              label="Post Code"
              disabled={availablityCheck.loading || addressSearch.loading}
              onKeyDown={showOrHideError}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="postCode"
              data-testid="postCode"
            />
            <Button
              className="button-primary"
              content="Find Address"
              loading={addressSearch.loading}
              disabled={
                availablityCheck.loading || !values.postCode || errors.postCode
              }
            />
          </Form.Group>
          {touched.postCode && errors.postCode && (
            <span className="availability__error">
              {POST_CODE_ERROR_MESSAGE}
            </span>
          )}
        </Form>

        <Form onSubmit={onAvailablityFormSubmit}>
          {addressSearch.data && (
            <select
              disabled={availablityCheck.loading}
              size={10}
              name="address"
              value={values.address}
              onChange={formik.handleChange}
              data-testid="address"
              style={{
                width: "100%",
                maxHeight: "200px",
                overflowY: "auto",
                fontSize: "14px",
                lineHeight: "1.4",
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "4px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <option value="" hidden></option>
              {addressSearch.data.map(({ id, address, addressReference }) => {
                const qualifier = addressReference?.qualifier || "";
                const backgroundColor =
                  qualifier.toLowerCase() === "gold"
                    ? "#fff7d1"
                    : qualifier.toLowerCase() === "silver"
                    ? "#f5f5f5"
                    : "white";
                const textColor = "#333";

                return (
                  <option
                    key={id}
                    value={id}
                    style={{
                      backgroundColor,
                      color: textColor,
                      padding: "5px",
                      borderBottom: "1px solid #ddd",
                      fontWeight:
                        qualifier.toLowerCase() === "gold" ||
                        qualifier.toLowerCase() === "silver"
                          ? "bold"
                          : "normal",
                    }}
                  >
                    {address} {qualifier ? ` - ${qualifier}` : ""}
                  </option>
                );
              })}
            </select>
          )}
        </Form>

        {errorBannerMessage && <Message error {...errorBannerMessage} />}
      </Panel>

      <Button
        className="button-primary"
        content="Check Availability"
        loading={availablityCheck.loading}
        disabled={errors.address || errors.phoneNumber}
        onClick={onAvailablityFormSubmit}
        floated="right"
      />
    </>
  );
}
