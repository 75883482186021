import React from "react";
import Panel from "../Panel";
import {
  Dimmer,
  Loader,
  Message,
  Segment,
  Table,
  Pagination,
  Input,
  Header
} from "semantic-ui-react";
import { searchOrders } from "../../libs/requests/notifications";
import NotificationMessage from "./NotificationMessage";
import moment from "moment";
import { Link } from 'react-router-dom';

export default class OrderNotifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      currentPage: 1,
      pageSize: 10,
      totalRecords: 0,
      isLoading: false,
      searchTerm: "",
      startDate: moment().subtract(1, "year").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      noData: false,
    };
  }

  async componentDidMount() {
    await this.fetchNotifications();
  }

  
  fetchNotifications = async () => {
    this.setState({ isLoading: true, noData: false });
    const { searchTerm, startDate, endDate } = this.state;

    try {
      const result = await searchOrders(searchTerm, startDate, endDate);
      const allNotifications = [];

      // Collect zenUpdates and giganetUpdates into a single array
      Object.entries(result.zenUpdates).forEach(([key, notifications]) => {
        allNotifications.push(
          ...notifications.map((notification) => ({
            ...notification,
            reference: key.toUpperCase(), // Capitalizing the reference from zenUpdates
          }))
        );
      });

      // Filter out notifications with "No message available"
      const validNotifications = allNotifications.filter((notification) => {
        return (
          notification.payload?.message &&
          notification.payload.message !== "No message available"
        );
      });

      // Set notifications and totalRecords
      this.setState({
        notifications: validNotifications,
        totalRecords: validNotifications.length,
        isLoading: false,
        noData: validNotifications.length === 0,
      });
    } catch (error) {
      console.error("Error fetching orders:", error);
      this.setState({ isLoading: false, noData: true });
    }
  };

  handleSearchChange = (e) => {
    this.setState({ searchTerm: e.target.value }, this.fetchNotifications);
  };

  handleDateChange = (dateType, value) => {
    this.setState({ [dateType]: value }, this.fetchNotifications);
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  render() {
    const {
      notifications,
      currentPage,
      pageSize,
      totalRecords,
      isLoading,
      noData,
      searchTerm,
      startDate,
      endDate,
    } = this.state;

    // Calculate pagination
    const startIndex = (currentPage - 1) * pageSize;
    const paginatedNotifications = notifications.slice(
      startIndex,
      startIndex + pageSize
    );

    const header = (
        <Header
          as="h2"
          textAlign="center"
          style={{
            margin: "20px 0",
            color: "black", // Set the text color to black or white as needed
            zIndex: 1000, // Bring the text to the front
            backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: Add a semi-transparent background to ensure readability
            padding: "10px", // Optional: Add padding for better spacing
            borderRadius: "5px", // Optional: Add border radius for better aesthetics
          }}
        >
          Order Notifications
         
        </Header>
      );


    return (
    
      <Segment>
        <Dimmer active={isLoading} inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>

          <Panel header={header}></Panel>

        <Input
          placeholder="Search by reference..."
          value={searchTerm}
          onChange={this.handleSearchChange}
        />

        {/* <Input
          type="date"
          value={startDate}
          onChange={(e) => this.handleDateChange('startDate', e.target.value)}
        />
        <Input
          type="date"
          value={endDate}
          onChange={(e) => this.handleDateChange('endDate', e.target.value)}
        /> */}

        {noData && <Message>No notifications available.</Message>}

        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Reference</Table.HeaderCell>
              <Table.HeaderCell style={{ width: "200px" }}>
                Date
              </Table.HeaderCell>
              <Table.HeaderCell>Message</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {paginatedNotifications.map((notification) => (
              <Table.Row key={notification.id}>
                <Table.Cell>
                  <Link to={`/Order/${notification.reference}`}>
                    {notification.reference}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  {moment(notification.dateIssued).format("YYYY-MM-DD")}
                </Table.Cell>
                <Table.Cell>{notification.payload.message}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination
          totalPages={Math.ceil(totalRecords / pageSize)}
          activePage={currentPage}
          onPageChange={(e, { activePage }) =>
            this.handlePageChange(activePage)
          }
        />
      </Segment>
    );
  }
}
