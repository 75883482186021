import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Accordion, AccordionContent, AccordionTitle, Button, Dimmer, Grid, Icon, Loader, Message, Segment } from 'semantic-ui-react';
import Panel from '../Panel';
import { authContext } from '../../containers/AuthProvider';
import { getDiagnostics } from '../../libs/requests/getDiagnostics';

const getSyncStatusLabel = (status) => {
  switch (status) {
    case 0: return "Not Specified";
    case 1: return "In Sync";
    case 2: return "Out of Sync";
    default: return "Unknown";
  }
};

const ServiceTest = ({ serviceData }) => {
  const { syncInformation, serviceTest } = serviceData || {};

  return (
    <>
      {/* Sync Information - Last Run Test Detail */}
      <Panel header="Sync Information - Last Run Test Detail">
        <Segment padded style={{ backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column width={8}><strong><Icon name="sync" /> Sync Status:</strong></Grid.Column>
              <Grid.Column width={8}>{getSyncStatusLabel(syncInformation?.syncStatus) || "N/A"}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}><strong><Icon name="calendar check" /> Test Run:</strong></Grid.Column>
              <Grid.Column width={8}>{serviceData?.dataFrom ? new Date(serviceData.dataFrom).toLocaleString() : "Unknown"}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}><strong><Icon name="id badge" /> Service ID:</strong></Grid.Column>
              <Grid.Column width={8}>{serviceData?.serviceID || "Unknown"}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}><strong><Icon name="user" /> Test Run by:</strong></Grid.Column>
              <Grid.Column width={8}>{serviceData?.userName === "ws-api-faelix" ? "ws-api-hightide" : serviceData?.userName || "Unknown"}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Panel>

      {/* Service Tests */}
      <Panel header="Service Tests">
        <Segment padded style={{ backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column width={8}><strong><Icon name="check circle" /> Test Outcome:</strong></Grid.Column>
              <Grid.Column width={8}>{serviceTest?.testOutcome === 1 ? "Pass" : serviceTest?.testOutcome === 0 ? "Fail" : "Unknown"}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}><strong><Icon name="code" /> Test Outcome Code:</strong></Grid.Column>
              <Grid.Column width={8}>{serviceTest?.testOutcomeCode || "N/A"}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}><strong><Icon name="info" /> Description:</strong></Grid.Column>
              <Grid.Column width={8}>{serviceTest?.description || "N/A"}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}><strong><Icon name="map marker" /> Main Fault Location:</strong></Grid.Column>
              <Grid.Column width={8}>{serviceTest?.mainFaultLocation === 1 ? "No Fault Found" : "Unknown"}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}><strong><Icon name="sitemap" /> DP Type:</strong></Grid.Column>
              <Grid.Column width={8}>{serviceTest?.dpType || "N/A"}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}><strong><Icon name="exclamation circle" /> Fault Report Advised:</strong></Grid.Column>
              <Grid.Column width={8}>{serviceTest?.faultReportAdvised ? "True" : "False"}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}><strong><Icon name="bug" /> Proactive Fault ID:</strong></Grid.Column>
              <Grid.Column width={8}>{serviceTest?.proactiveFaultId || "N/A"}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}><strong><Icon name="clock" /> Target Fix Date & Time:</strong></Grid.Column>
              <Grid.Column width={8}>{serviceTest?.targetFixDateTime || "N/A"}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}><strong><Icon name="tasks" /> Service Level:</strong></Grid.Column>
              <Grid.Column width={8}>{serviceTest?.serviceLevel || "Unknown"}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}><strong><Icon name="rss" /> Multicast Service:</strong></Grid.Column>
              <Grid.Column width={8}>{serviceTest?.multicastService || "N/A"}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}><strong><Icon name="barcode" /> Line Test Reference:</strong></Grid.Column>
              <Grid.Column width={8}>{serviceTest?.lineTestReference || "N/A"}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Panel>
    </>
  );
};

export default function ServiceTests() {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [serviceData, setServiceData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isRunningTest, setIsRunningTest] = useState(false);

  const { reference } = useParams();
  const { permissions } = useContext(authContext);

  const fetchData = async () => {
    try {
      if (permissions.servicesAccess) {
        const response = await getDiagnostics(reference, ["ServiceTestLatest"]);
        setServiceData(response.serviceTestLatest || {});
        setIsError(false);
      }
    } catch (error) {
      console.error("Error fetching service test data:", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const runTest = async () => {
    setIsRunningTest(true);
    setIsError(false);
    try {
      const { startFttpServiceTestResponse } = await getDiagnostics(reference, ["StartFttpServiceTest"]);
      const { testId } = startFttpServiceTestResponse;

      const pollTestResult = async () => {
        try {
          const queryString = `testId=${testId}`;
          const result = await getDiagnostics(reference, ["GetTestResultFTTP"], "POST", {}, queryString);

          if (result?.testResultFTTP?.errorMessage) {
            setIsRunningTest(false);
            setIsError(true);
            console.error("Error during test:", result.testResultFTTP.errorMessage);
          } else if (result?.testResultFTTP?.serviceTest?.dataFrom) {
            setServiceData(prev => ({ ...prev, serviceTest: result.testResultFTTP.serviceTest }));
            setIsRunningTest(false);
          } else {
            setTimeout(pollTestResult, 2000);
          }
        } catch (error) {
          console.error("Error polling test result:", error);
          setIsRunningTest(false);
          setIsError(true);
        }
      };

      pollTestResult();
    } catch (error) {
      console.error("Error starting the service test:", error);
      setIsRunningTest(false);
      setIsError(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, [permissions.servicesAccess, reference]);

  const handleAccordionClick = (e, { index }) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  if (isLoading) {
    return (
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    );
  }

  return (
    <Panel header="Service Tests">
      {isError && (
        <Message error header="Unable to retrieve test data at this time, please try again later." />
      )}

      {!serviceData?.serviceTest?.dataFrom && isRunningTest && (
        <Message info header="Test is in progress, please check back later for the results as this is a long running test." />
      )}

      <Accordion fluid styled>
        <AccordionTitle active={activeIndex === 0} index={0} onClick={handleAccordionClick}>
          <Icon name="dropdown" />
          Service Test
        </AccordionTitle>
        <AccordionContent active={activeIndex === 0}>
          <ServiceTest serviceData={serviceData} />
        </AccordionContent>
      </Accordion>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1em' }}>
        <Button
          color="blue"
          onClick={runTest}
          disabled={isRunningTest}
          loading={isRunningTest}
        >
          Run Test
        </Button>
      </div>
    </Panel>
  );
}
