import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Button,
  Dimmer,
  Grid,
  Icon,
  Loader,
  Message,
  Segment,
} from "semantic-ui-react";
import Panel from "../Panel";
import { authContext } from "../../containers/AuthProvider";
import { getDiagnostics } from "../../libs/requests/getDiagnostics";

const getTestOutcomeLabel = (value) => {
  switch (value) {
    case 1: return "Pass";
    case 2: return "Fail";
    case 3: return "Inconclusive";
    case 4: return "TimedOut";
    case 5: return "PrematureTermination";
    default: return "Not Specified";
  }
};

const getSyncStatusLabel = (status) => {
  switch (status) {
    case 0:
      return "Not Specified";
    case 1:
      return "In Sync";
    case 2:
      return "Out of Sync";
    default:
      return "Unknown";
  }
};

// Helper function to translate `mainFaultLocation` enum
const getMainFaultLocationLabel = (value) => {
  switch (value) {
    case 1: return "OK";
    case 2: return "Customer Apparatus (CA)";
    case 3: return "Between Network Termination Equipment and Distribution Point (CE)";
    case 4: return "Line (LN)";
    case 5: return "Exchange (EX)";
    case 6: return "Frames Unit (FU)";
    case 7: return "Diagnostics Test (DT)";
    case 8: return "Test Office (TO)";
    case 9: return "Local Office (LO)";
    case 10: return "End Office (EO)";
    case 11: return "Central Office (CO)";
    case 12: return "Distribution Office (DO)";
    case 13: return "Customer Installation (CI)";
    default: return "Not Specified";
  }
};

// Helper function to translate `state` enum
const getStateLabel = (value) => {
  switch (value) {
    case 0: return "Not Set";
    case 10: return "Pending";
    case 20: return "Success";
    case 30: return "Test Type Not Supported";
    case 40: return "Fail";
    default: return "Unknown";
  }
};

const ServiceTest = ({ serviceData }) => {
  const { upstreamLinkInformation, downstreamLinkInformation } = serviceData || {};

  return (
    <>
      {/* Sync Information - Last Run Test Detail */}
      <Panel header="Sync Information - Last Run Test Detail">
        <Segment
          padded
          style={{ backgroundColor: "#f9f9f9", borderRadius: "8px" }}
        >
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="sync" /> Sync Status:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {getSyncStatusLabel(serviceData?.syncStatus) || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="calendar" /> Last Sync Time:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {serviceData?.currentSyncTime || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="upload" /> Upstream Sync Speed:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {upstreamLinkInformation?.syncSpeed
                  ? `${upstreamLinkInformation.syncSpeed} Mbps`
                  : "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="download" /> Downstream Sync Speed:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {downstreamLinkInformation?.syncSpeed
                  ? `${downstreamLinkInformation.syncSpeed} Mbps`
                  : "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>
                  <Icon name="warning" /> Error Message:
                </strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {serviceData?.errorMessage || "N/A"}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Panel>

      {/* Upstream Link Information */}
      <Panel header="Upstream Link Information">
        <Segment
          padded
          style={{ backgroundColor: "#f9f9f9", borderRadius: "8px" }}
        >
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>Sync Speed:</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {upstreamLinkInformation?.syncSpeed
                  ? `${upstreamLinkInformation.syncSpeed} Mbps`
                  : "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>SNR Margin:</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {upstreamLinkInformation?.snrMargin || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>Attenuation:</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {upstreamLinkInformation?.attenuation || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>Ordered Product Speed:</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {upstreamLinkInformation?.orderedProductSpeed || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>Estimated Speed at Provisioning:</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {upstreamLinkInformation?.estimatedSpeedAtProvisioning || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>MTBE CRC Inter:</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {upstreamLinkInformation?.mtbe?.crcInter || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>Traffic:</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {upstreamLinkInformation?.traffic || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>BRAS:</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {upstreamLinkInformation?.bras || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>Max Stable Rate:</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {upstreamLinkInformation?.maxStableRate || "N/A"}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Panel>

      {/* Downstream Link Information */}
      <Panel header="Downstream Link Information">
        <Segment
          padded
          style={{ backgroundColor: "#f9f9f9", borderRadius: "8px" }}
        >
          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>Sync Speed:</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {downstreamLinkInformation?.syncSpeed
                  ? `${downstreamLinkInformation.syncSpeed} Mbps`
                  : "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>SNR Margin:</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {downstreamLinkInformation?.snrMargin || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>Attenuation:</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {downstreamLinkInformation?.attenuation || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>Ordered Product Speed:</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {downstreamLinkInformation?.orderedProductSpeed || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>Estimated Speed at Provisioning:</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {downstreamLinkInformation?.estimatedSpeedAtProvisioning || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>MTBE CRC Inter:</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {downstreamLinkInformation?.mtbe?.crcInter || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>Traffic:</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {downstreamLinkInformation?.traffic || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>BRAS:</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {downstreamLinkInformation?.bras || "N/A"}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                <strong>Max Stable Rate:</strong>
              </Grid.Column>
              <Grid.Column width={8}>
                {downstreamLinkInformation?.maxStableRate || "N/A"}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Panel>
    </>
  );
};


export default function ServiceTestsCopper() {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [serviceData, setServiceData] = useState(null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isRunningTest, setIsRunningTest] = useState(false);

  const { reference } = useParams();
  const { permissions } = useContext(authContext);

  const fetchData = async () => {
    try {
      if (permissions.servicesAccess) {
        const response = await getDiagnostics(reference, ["ServiceTestCopperLatest"]);
        debugger;
        setServiceData(response.serviceTestCopperLatest || {});
        setIsError(false);
      }
    } catch (error) {
      console.error("Error fetching service test data:", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const runTest = async () => {
    setIsRunningTest(true);
    setIsError(false);
    try {
      const {startCopperServiceTestResponse} = await getDiagnostics(reference, [
        "StartCopperServiceTest",
      ]);
      debugger;
      const { testId } = startCopperServiceTestResponse;

      const pollTestResult = async () => {
        try {
          const queryString = `testId=${testId}`;
          const result = await getDiagnostics(
            reference,
            ["GetCopperServiceTestResult"],
            "POST",
            {},
            queryString
          );
          debugger;

          if (result?.getCopperServiceTestResult?.errorMessage) {
            setIsRunningTest(false);
            setIsError(true);
            console.error(
              "Error during test:",
              result.getCopperServiceTestResult.errorMessage
            );
          } else if (
            result?.getCopperServiceTestResult &&
            result?.getCopperServiceTestResult?.dataFrom
          ) {
            setServiceData(result.getCopperServiceTestResult); // Update the entire state
            setIsRunningTest(false);
          } else {
            setTimeout(pollTestResult, 2000);
          }
        } catch (error) {
          console.error("Error polling test result:", error);
          setIsRunningTest(false);
          setIsError(true);
        }
      };

      pollTestResult();
    } catch (error) {
      console.error("Error starting the service test:", error);
      setIsRunningTest(false);
      setIsError(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, [permissions.servicesAccess, reference]);

  const handleAccordionClick = (e, { index }) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  if (isLoading) {
    return (
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    );
  }

  return (
    <Panel header="Service Tests">
      {isError && (
        <Message
          error
          header="Unable to retrieve test data at this time, please try again later."
        />
      )}

      {!serviceData?.serviceTest?.dataFrom && isRunningTest && (
        <Message
          info
          header="Test is in progress, please check back later to view the results as this is a long running test."
        />
      )}

      <Accordion fluid styled>
        <AccordionTitle
          active={activeIndex === 0}
          index={0}
          onClick={handleAccordionClick}
        >
          <Icon name="dropdown" />
          Service Test
        </AccordionTitle>
        <AccordionContent active={activeIndex === 0}>
          <ServiceTest serviceData={serviceData} />
        </AccordionContent>
      </Accordion>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1em",
        }}
      >
        <Button
          color="blue"
          onClick={runTest}
          disabled={isRunningTest}
          loading={isRunningTest}
        >
          Run Test
        </Button>
      </div>
    </Panel>
  );
}
